import { flag } from 'country-emoji';

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_FORMAT_HOURS = 'MMM DD, YYYY HH:mm';
export const ALL_LINKS = 'ALL';
export const ALL_LINKS_FOR_CAMPAIGN = 'ALL';
export const UNCLASSIFIED_LINKS = 'NONE';
export const UNCLASSIFIED_LINKS_INTO_SUBCAMPAIGN = 'NONE';

export const ALL_REDIRECTS = 'ALL';
export const ALL_REDIRECTS_FOR_CAMPAIGN = 'ALL';
export const UNCLASSIFIED_REDIRECTS = 'NONE';
export const UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN = 'NONE';

export const MAX_REDIRECTS_COUNT = 50000;

export const CAMPAIGN_STATUSES = {
  STATUS_RETRY_IMPORT_LATER: -51,
  STATUS_FAILED_IMPORT: -50,
  STATUS_WAITING: -10,
  STATUS_WAITING_IMPORT: -5,
  STATUS_PAUSED: 1,
  STATUS_DELETED: 2,
  STATUS_REJECTED: 3,
  STATUS_ACTIVE: 4,
  STATUS_FINISHED: 5,
  STATUS_INVALID_CREATIVE: -101,
};

export const ACTIVE_CAMPAIGN_OPTIONS = {
  ACTIVE_GOOGLE: 'activeGoogle',
  ACTIVE_FACEBOOK: 'activeFacebook',
  ACTIVE_TIKTOK: 'activeTiktok',
};

export const CAMPAIGNS_TABLE_COLUMN_NAMES = {
  status: 'Status',
  impressions: 'Impressions',
  clicks: 'Clicks',
  amazonClickThroughs: 'Amazon Clickthroughs',
  adSpent: 'Ad Spend',
  averageCpc: 'CPC',
  amazonTotalAddToCart: 'Add to Cart',
  amazonTotalPurchases: 'Purchases',
  amazonTotalSales: 'Revenue',
  conversionRate: 'Conversion Rate',
  addToCartRate: 'Add to Cart Rate',
  clickThroughRate: 'Clickthrough rate',
  amazonNewToBrandPurchases: 'New to Brand Purchases',
  amazonNewToBrandSales: 'New to Brand Revenue',
  netMargin: 'Net Margin',
  roas: 'ROAS',
  acos: 'ACOS',
  referralBonus: 'Average Brand Referral Bonus',
  googleCampaign: 'Location',
  keywords: 'Keywords',
  createdAt: 'Created At',
  providerStatus: 'Provider Status',
};

export const CAMPAIGNS_TABLE_DEFAULT_COLUMNS = [
  'status',
  'impressions',
  'clicks',
  'amazonClickThroughs',
  'adSpent',
  'averageCpc',
  'amazonTotalAddToCart',
  'amazonTotalPurchases',
  'amazonTotalSales',
  'netMargin',
  'roas',
  'acos',
  'referralBonus',
  'googleCampaign',
  'keywords',
  'createdAt',
  'providerStatus',
  'convertedClicks',
];

export const C6_NAVBAR_HEIGHT = 48;

export const WIDGET_API_CONNECTION = 'widget_api_connection';

export const AMAZON_MARKETPLACE_ID_US = 'ATVPDKIKX0DER';
export const AMAZON_MARKETPLACE_ID_BR = 'A2Q3Y263D00KWC';
export const AMAZON_MARKETPLACE_ID_CA = 'A2EUQ1WTGCTBG2';
export const AMAZON_MARKETPLACE_ID_MX = 'A1AM78C64UM0Y8';
export const AMAZON_MARKETPLACE_ID_AE = 'A2VIGQ35RCS4UG';
export const AMAZON_MARKETPLACE_ID_DE = 'A1PA6795UKMFR9';
export const AMAZON_MARKETPLACE_ID_EG = 'ARBP9OOSHTCHU';
export const AMAZON_MARKETPLACE_ID_ES = 'A1RKKUPIHCS9HS';
export const AMAZON_MARKETPLACE_ID_FR = 'A13V1IB3VIYZZH';
export const AMAZON_MARKETPLACE_ID_UK = 'A1F83G8C2ARO7P';
export const AMAZON_MARKETPLACE_ID_IN = 'A21TJRUUN4KGV';
export const AMAZON_MARKETPLACE_ID_IT = 'APJ6JRA9NG5V4';
export const AMAZON_MARKETPLACE_ID_NL = 'A1805IZSGTT6HS';
export const AMAZON_MARKETPLACE_ID_PL = 'A1C3SOZRARQ6R3';
export const AMAZON_MARKETPLACE_ID_SA = 'A17E79C6D8DWNP';
export const AMAZON_MARKETPLACE_ID_SE = 'A2NODRKZP88ZB9';
export const AMAZON_MARKETPLACE_ID_TR = 'A33AVAJ2PDY3EV';
export const AMAZON_MARKETPLACE_ID_SG = 'A19VAU5U5O7RUS';
export const AMAZON_MARKETPLACE_ID_AU = 'A39IBJ37TRP1C6';
export const AMAZON_MARKETPLACE_ID_JP = 'A1VC38T7YXB528';

export const AMAZON_MARKETPLACES = [
  { id: AMAZON_MARKETPLACE_ID_US, flag: flag('us'), name: 'Amazon.com', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_UK, flag: flag('gb'), name: 'Amazon.co.uk', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_DE, flag: flag('de'), name: 'Amazon.de', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_FR, flag: flag('fr'), name: 'Amazon.fr', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_JP, flag: flag('jp'), name: 'Amazon.jp', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_CA, flag: flag('ca'), name: 'Amazon.ca', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_IT, flag: flag('it'), name: 'Amazon.it', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_ES, flag: flag('es'), name: 'Amazon.es', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_IN, flag: flag('in'), name: 'Amazon.in', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_MX, flag: flag('mx'), name: 'Amazon.com.mx', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_BR, flag: flag('br'), name: 'Amazon.com.br', auditAvailable: true },
  { id: AMAZON_MARKETPLACE_ID_AE, flag: flag('ae'), name: 'Amazon.ae', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_EG, flag: flag('eg'), name: 'Amazon.eg', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_NL, flag: flag('nl'), name: 'Amazon.nl', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_PL, flag: flag('pl'), name: 'Amazon.pl', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_SA, flag: flag('sa'), name: 'Amazon.sa', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_SE, flag: flag('se'), name: 'Amazon.se', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_TR, flag: flag('tr'), name: 'Amazon.com.tr', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_SG, flag: flag('sg'), name: 'Amazon.sg', auditAvailable: false },
  { id: AMAZON_MARKETPLACE_ID_AU, flag: flag('au'), name: 'Amazon.com.au', auditAvailable: false },
];
