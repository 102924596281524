import React from 'react';

import { DataProviderModal, getCombinedLatestAndError } from './DataProviderModal';
import { ClockCheck } from '../../../../common/icons/ClockCheck';
import { DATE_FORMAT_HOURS } from 'consts/consts';
import moment from 'moment';

export const DataProviderTimestampButton = ({ account }) => {
  const adsProviders = account.adsProviders.filter((p) => !p.generated);
  const productProviders = account.productProviders;

  const { latestSync } = getCombinedLatestAndError(adsProviders, productProviders);

  return (
    <DataProviderModal account={account}>
      <span style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
        <ClockCheck style={{ top: '0.3rem', position: 'relative', marginRight: '0.4rem' }}></ClockCheck>
        {moment(latestSync).format(DATE_FORMAT_HOURS)}
      </span>
    </DataProviderModal>
  );
};
