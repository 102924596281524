import React from 'react';

import moment from 'moment';

import { useDataProviderAllowed } from './useDataProviderAllowed';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { DATE_FORMAT_HOURS } from 'consts/consts';

import styled from 'styled-components';
import { AmazonLogo } from 'components/common/icons/Amazon';

function adsMetricsFn(provider) {
  return [
    provider.campaignMetricsProcessingStatus.lastProcessedAt,
    provider.campaignMetricsProcessingStatus.lastErrorAt,
  ];
}

function adsClickConversionsFn(provider) {
  return [provider.clickConversionsUploadedAt, null];
}

function productSyncFn(provider) {
  return [provider.lastProcessedAt, provider.lastErrorAt];
}

function productBsrSyncFn(provider) {
  return [provider.bsrLastProcessedAt, null];
}

function productOrderSyncFn(provider) {
  return [provider.orderReportLastProcessedAt, null];
}

function getLatestAndError(providers, getFn) {
  let failedProviders = [];
  let latestSync = null;

  for (let provider of providers) {
    const [syncedAt, errorAt] = getFn(provider);

    if (!syncedAt || moment(syncedAt).isSameOrBefore(0)) {
      continue;
    }

    if (!latestSync || latestSync < syncedAt) {
      latestSync = syncedAt;
    }

    if (errorAt) {
      failedProviders.push(provider);
    }
  }

  return [latestSync, failedProviders];
}

export function getCombinedLatestAndError(adsProviders, productProviders) {
  const entries = [
    [adsProviders, adsMetricsFn],
    [adsProviders, adsClickConversionsFn],
    [productProviders, productSyncFn],
    [productProviders, productBsrSyncFn],
    [productProviders, productOrderSyncFn],
  ];

  // Loop through entries and ads providers and call getLatestAndError
  const results = entries.map(([providers, getFn]) => {
    return getLatestAndError(providers, getFn);
  });

  // Aggregate results
  return results.reduce(
    (acc, [latestSync, failedProviders]) => {
      if (!acc.latestSync || latestSync > acc.latestSync) {
        acc.latestSync = latestSync;
      }
      acc.failedProviders.push(...failedProviders);
      return acc;
    },
    { latestSync: null, failedProviders: [] },
  );
}

function renderAdsProvider(name, providerType, frequency, providerDetails) {
  const [latestSync, failedProviders] = providerDetails;

  return (
    <li>
      <AdsProviderLogo style={{ height: 24, width: 24, marginTop: 2 }} providerType={`${providerType}`} />
      <div class="name">
        <div>{name}</div>
        <div class="details">
          <ul>
            {failedProviders.map((provider) => (
              <li>{provider.externalAccountDescriptiveName}</li>
            ))}
          </ul>
          <div class="frequency"> {frequency}</div>
        </div>
      </div>

      <div class="status">
        {failedProviders.length > 0 ? (
          <span class="error">
            The most recent data refresh was not successful. Please reach out to support at help@carbon6.io&nbsp;
          </span>
        ) : (
          <>
            Last completed:&nbsp;
            <span>{latestSync ? moment(latestSync).format(DATE_FORMAT_HOURS) : 'N/A'}</span>
          </>
        )}
      </div>
    </li>
  );
}

function renderProductProvider(name, frequency, providerDetails) {
  const [latestSync, failedProviders] = providerDetails;

  return (
    <li>
      <AmazonLogo style={{ height: 24, width: 24, marginTop: 2 }} />
      <div class="name">
        <div>{name}</div>
        <div class="details">
          <ul class="failed-providers">
            {failedProviders.map((provider) => (
              <li>{provider.externalAccountDescriptiveName}</li>
            ))}
          </ul>
          <div class="frequency">{frequency}</div>
        </div>
      </div>

      <div class="status">
        {failedProviders.length > 0 ? (
          <span class="error">
            The most recent data refresh was not successful. Please reach out to support at help@carbon6.io&nbsp;
          </span>
        ) : (
          <>
            Last completed:&nbsp;
            <span>{latestSync ? moment(latestSync).format(DATE_FORMAT_HOURS) : 'N/A'}</span>
          </>
        )}
      </div>
    </li>
  );
}

/**
 * A hook used to open the data provider modal
 * @param {object} { account }
 * @returns {[func,boolean]} A function that opens the data provider modal and a `boolean` of if user is allowed.
 */
export const DataProviderModal = ({ account, children }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isAllowed = useDataProviderAllowed();

  if (!isAllowed) {
    return [() => undefined, false];
  }

  const modalRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  React.useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const MyModal = styled.div`
    position: fixed;

    background: rgba(250, 250, 250, 1);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.35);

    width: 800px;
    max-height: calc(100vh - 124px);
    top: 62px;
    right: 62px;
    border-radius: 20px;
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    gap: 24px;
    z-index: 9999;

    h1 {
      text-align: left;

      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0%;
      vertical-align: middle;
      text-transform: capitalize;
      margin: 10px;
    }
    .body {
      padding: 15px;

      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0%;
    }

    ul.items {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 40px;

      > li {
        display: grid;
        gap: 12px;
        grid-template-columns: 24px 10fr 5fr;

        .img {
          margin-top: 10px;
        }
        .name {
          flex-grow: 1;
          font-size: 14px;
        }
        .status {
          font-size: 13px;
          color: rgba(26, 33, 255, 1);
          .error {
            color: rgba(217, 63, 33, 1);
          }
        }
        .frequency {
          color: rgba(89, 89, 89, 1);
        }
        .details {
          margin-top: 8px;
          ul {
            list-style-type: disc;
            list-style-position: inside;
            margin: 0;
            padding: 0;
            margin-bottom: 8px;

            > li {
              overflow: hidden;
            }
          }
          font-size: 12px;
        }
      }
    }
  `;

  const adsProviders = account.adsProviders.filter((p) => !p.generated);
  const facebookProviders = adsProviders.filter((p) => p.providerType === 'FACEBOOK');
  const googleProviders = adsProviders.filter((p) => p.providerType === 'GOOGLE');
  const tiktokProviders = adsProviders.filter((p) => p.providerType === 'TIKTOK');

  const productProviders = account.productProviders;

  const adMetricFrequency = 'once a day'; // cron-import-campaigns-from-adsprovider
  const adConversionsFrequency = 'twice a day 2:00 PM and 10:00 PM UTC, can take up to an hour'; // cron-upload-google-click-conversions

  const prodMetricsFrequency = 'every 6 hours, can take up to 2 hours'; // cron-productprovider-attribution
  const prodBSRFrequency = 'once a day 00:00 UTC, can take up to 2 hours'; // cron-sync-bsr
  const prodOrdersFrequency = 'once a day 09:00 AM UTC, can take up to 4 hours'; // cron-sync-spa-order-stats

  return (
    <>
      <div onMouseUp={toggleModal} ref={modalRef}>
        {children}
        {isModalOpen && (
          <MyModal style={{ display: 'unset' }}>
            <h1>Data Synced</h1>
            <div class="body">
              <ul class="items">
                {googleProviders.length > 0 && (
                  <>
                    {renderAdsProvider(
                      'Google Metrics',
                      'GOOGLE',
                      adMetricFrequency,
                      getLatestAndError(googleProviders, adsMetricsFn),
                    )}
                    {renderAdsProvider(
                      'Google Click Conversions',
                      'GOOGLE',
                      adConversionsFrequency,
                      getLatestAndError(googleProviders, adsClickConversionsFn),
                    )}
                  </>
                )}
                {facebookProviders.length > 0 && (
                  <>
                    {renderAdsProvider(
                      'Facebook Metrics',
                      'FACEBOOK',
                      adMetricFrequency,
                      getLatestAndError(facebookProviders, adsMetricsFn),
                    )}
                    {renderAdsProvider(
                      'Facebook Click Conversions',
                      'FACEBOOK',
                      adConversionsFrequency,
                      getLatestAndError(facebookProviders, adsClickConversionsFn),
                    )}
                  </>
                )}
                {tiktokProviders.length > 0 && (
                  <>
                    {renderAdsProvider(
                      'TikTok Metrics',
                      'TIKTOK',
                      adMetricFrequency,
                      getLatestAndError(tiktokProviders, adsMetricsFn),
                    )}
                    {renderAdsProvider(
                      'TikTok Click Conversions',
                      'TIKTOK',
                      adConversionsFrequency,
                      getLatestAndError(tiktokProviders, adsClickConversionsFn),
                    )}
                  </>
                )}

                {productProviders.length > 0 && (
                  <>
                    {renderProductProvider(
                      'Amazon Product Metrics',
                      prodMetricsFrequency,
                      getLatestAndError(productProviders, productSyncFn),
                    )}
                    {renderProductProvider(
                      'Best Selling Rank',
                      prodBSRFrequency,
                      getLatestAndError(productProviders, productBsrSyncFn),
                    )}
                    {renderProductProvider(
                      'Amazon Order Data',
                      prodOrdersFrequency,
                      getLatestAndError(productProviders, productOrderSyncFn),
                    )}
                  </>
                )}
              </ul>
            </div>
          </MyModal>
        )}
      </div>
    </>
  );
};
