import React, { Fragment, useEffect, useState } from 'react';

import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _, { debounce } from 'lodash';
import { unset } from 'lodash/object';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import axios from 'lib/axios.factory';
import { useSaveProductOnProviderMutation } from 'stores/productsSlice';

import { AmazonProviderSelector } from './AmazonProviderSelector';
import { ProductTagsSelector } from './ProductTagsSelector';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { searchProductByURL } from '../../../../lib/adsPlateformApi';
import { getAmazonProfiles, getCurrencyCode } from '../../../../lib/amazon';
import { track } from '../../../../lib/analytics';
import { strings } from '../../../../locales/strings';
import { CurrencyInput } from '../../../common/antd/inputs/CurrencyInput';
import { RadioButtonTabs } from '../../../common/antd/RadioButtonTabs';
import { CompetitorsSelector } from '../../../common/CompetitorsSelector';
import { MutedText } from '../../../common/MutedText';
import { keywordsNormalizer } from '../../../common/normalizers/Keyword';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';
import { NewProductProviderButton } from '../../AmazonAttribution/common/NewProductProviderButton';

export const TYPE_PRODUCT = '';
export const TYPE_STORE = 'STORE';
export const TYPE_OTHER = 'OTHER';

function NewProductModal(props: { account: accountT, limitations: accLimitationsT, onFinish: Function }) {
  const [product, setProduct] = useState({ externalId: undefined, url: '' });
  const [isSearching, setIsSearching] = useState(false);
  const [productType, setProductType] = useState(TYPE_PRODUCT);
  const [productForm] = useForm();
  const [storeForm] = useForm();
  const [saveProductOnProvider, { isSuccess, isLoading: isSaving }] = useSaveProductOnProviderMutation();

  const currency = getCurrencyCode(props.account.productProviders);
  const maxKeywords = props.limitations.limitProductKeyword;
  const provider = props.account.productProviders[0];
  const loading = isSearching || isSaving;
  const { messages } = useIntl();

  useEffect(() => {
    if (isSuccess) {
      props.onFinish();
    }
  }, [isSuccess]);

  let availableProviders = getAmazonProfiles(props.account).filter(
    (p: productProviderT) => product.marketplaceId === provider.amazonMarketplaceId,
  );

  async function saveProduct(values) {
    const productToSave = {
      ...product,
      ...values,
      type: productType,
      provider: provider.providerType,
      productUrl: product.productUrl || values.productUrl,
      netMarginMicros: Math.round(values.netMargin * 1000000),
      priceMicros: Math.round(values.price * 1000000),
      keywords: values.keywords || [],
      currency,
      tags: values.tags,
      marketplaceName: provider.marketplaceId,
    };

    if (productType === TYPE_PRODUCT) {
      productToSave.externalId = values.externalId;
    } else {
      // we don't have asin for this kind of product so we have to create one unique fake one
      // need to be only alpha numeric to be compatible with URL standard
      productToSave.externalId = btoa(values.productUrl).replaceAll(/[\W_]+/g, '');
    }

    saveProductOnProvider({
      accountId: props.account.id,
      providerId: values.productProviderId,
      product: productToSave,
    });
  }

  async function searchProduct(url) {
    setIsSearching(true);
    let newProduct = {};

    const asins = url.match('dp/([A-Za-z0-9]{10})');

    if (asins && asins.length >= 1) {
      newProduct.externalId = asins[1].toUpperCase();
      newProduct.productUrl = url;

      if (newProduct.productUrl) {
        try {
          const response = await searchProductByURL(
            localStorage,
            axios,
            { accountId: props.account.id },
            newProduct.productUrl,
          );

          if (response.product.externalId !== newProduct.externalId) {
            message.error('Retrieved product has a different ASIN, please verify the URL and try again');
          }

          availableProviders = getAmazonProfiles(props.account).filter(
            (p: productProviderT) => response.product.marketplaceId === provider.amazonMarketplaceId,
          );

          if (availableProviders.length > 0) {
            response.product.productProviderId = availableProviders[0].id;
          }

          response.product.keywords = response.product.keywords || [];
          response.product.competitors = [];
          response.product.price = response.product.priceMicros / 1000000;
          response.product.tags = [];
          newProduct = { ...response.product, ...newProduct };
          setProduct(newProduct);
        } catch (e) {
          message.error('Unable to retrieve product, please verify the URL and try again');
        }
      }
    } else {
      setProduct({});
      message.error("Invalid URL, it doesn't contains ASIN");
    }

    productForm.resetFields();
    setIsSearching(false);
  }

  return (
    <Modal
      visible={true}
      layout="vertical"
      title="Add an Amazon product"
      okText="Save my product"
      width={800}
      okButtonProps={{ loading }}
      onCancel={props.onFinish}
      onOk={() => {
        if (productType === TYPE_PRODUCT) {
          productForm.submit();
        } else {
          storeForm.submit();
        }
      }}
    >
      <RadioButtonTabs
        topStyle={{ width: 330 }}
        label="Type of product"
        value={productType}
        onChange={(type) => {
          setProductType(type);
        }}
        options={[
          {
            value: TYPE_PRODUCT,
            label: 'Amazon product',
            children: NEW_PRODUCT_FORM(),
          },
          {
            value: TYPE_STORE,
            label: 'Store front',
            children: NEW_STORE_OTHER_FORM(),
          },
          {
            value: TYPE_OTHER,
            label: 'Other',
            children: NEW_STORE_OTHER_FORM(),
          },
        ]}
      />
    </Modal>
  );

  function NEW_PRODUCT_FORM() {
    const showUrlInput = (!product || !product.productUrl) && !loading;

    return (
      <Fragment key="NEW_PRODUCT_FORM">
        <Form value={product} initialValues={product} layout="vertical">
          {showUrlInput && (
            <div style={{ marginTop: '-20px' }}>
              <MutedText>Paste your product detail page URL. Example:</MutedText>
              <MutedText>
                <pre>https://www.amazon.com/AmazonBasics-Lightning-USB-Cable-Collection/dp/B082T5WH22</pre>
                <pre>OR</pre>
                <pre>https://www.amazon.com/dp/B082T5WH22</pre>
              </MutedText>
            </div>
          )}
          <Form.Item
            required
            disabled={!showUrlInput}
            style={{ visibility: loading ? 'hidden' : 'unset' }}
            label="Amazon Click-through URL"
            onChange={(v) => {
              const url = v.target.value;

              debounce(() => searchProduct(url), 500)();
            }}
            name="productUrl"
          >
            <InputSearchProduct disabled={product.productUrl} onReset={setProduct} loading={loading} />
          </Form.Item>
        </Form>
        {'eligible' in product && !product.eligible && (
          <>
            <Alert
              type="error"
              message={
                <Fragment>
                  Selected product could not be found under your Amazon Attribution account. You will still be able to
                  save the product, but we may not be able to show all the ad metrics for you.
                </Fragment>
              }
            />
            <br />
          </>
        )}
        {'buyboxOwnedByUser' in product && product.buyboxOwnedByUser == false && (
          <>
            <Alert type="error" message={<Fragment>Warning, you do not own the buy box for this listing.</Fragment>} />
            <br />
          </>
        )}
        {loading && <SmallCompactPlaceholder text="Please wait a few seconds" />}
        {!loading && availableProviders.length === 0 && product.productUrl && (
          <Fragment>
            <Alert
              type="error"
              message={
                <Fragment>
                  No Amazon account connected for this marketplace,
                  <br />
                  please add one or change product URL to match your marketplace
                </Fragment>
              }
            />
            <NewProductProviderButton
              account={props.account}
              limitationTest={(limitations, usage) =>
                usage.amazonProductProvider < limitations.LimitAmazonProductProvider
              }
            />
          </Fragment>
        )}
        {!loading && availableProviders.length > 0 && product.productUrl && (
          <Form form={productForm} layout="vertical" initialValues={product} onFinish={saveProduct}>
            <AmazonProviderSelector account={props.account} marketplaceId={product.marketplaceId} />
            <Form.Item required label="Amazon product name" name="name" rules={[{ required: true }]}>
              <Input size="large" placeholder="Super Cup"></Input>
            </Form.Item>
            <Form.Item required label="Amazon product description" name="description" rules={[{ required: true }]}>
              <Input.TextArea size="large" placeholder="Best cup of the world"></Input.TextArea>
            </Form.Item>
            <Form.Item required label="Product ASIN" name="externalId" rules={[{ required: true }]}>
              <Input size="large" placeholder="987656789"></Input>
            </Form.Item>
            <div style={{ display: 'flex', gap: '2em' }}>
              <div style={{ flex: '1' }}>
                <Form.Item required label="Amazon product image URL" name="productImageUrl">
                  <Input
                    size="large"
                    placeholder="https://www.amazon.fr/dp/B086FCC4GP/ref=cm_gf_aaay_iaad_d_p0_e0_qd3_0t1Xq7aonudPmoMCh9MC"
                  ></Input>
                </Form.Item>
              </div>
              <div style={{ flex: '0 0 60px' }}>
                <img width="100%" src={product.productImageUrl} />
              </div>
            </div>
            <Form.Item
              required
              label="Price by unit"
              validateTrigger="onBlur"
              name="price"
              rules={[{ required: true, min: 1, type: 'number' }]}
            >
              <CurrencyInput style={{ width: 100 }} currencyCode={currency}></CurrencyInput>
            </Form.Item>
            <Form.Item label="Net margin by unit" name="netMargin">
              <CurrencyInput style={{ width: 100 }} currencyCode={currency}></CurrencyInput>
            </Form.Item>
            <h3>Amazon search ranking</h3>
            <MutedText>
              Enter up to {maxKeywords} search terms or keywords to track and improve the ranking of your product.
            </MutedText>
            <Form.Item
              required
              label=""
              name="keywords"
              normalize={(values) => keywordsNormalizer(values || [])}
              rules={[
                {
                  max: maxKeywords,
                  type: 'array',
                  message: `maximum ${maxKeywords} keywords per product`,
                },
                {
                  message: 'keywords should not be longer that 150 characters',
                  validator: (_, keywords) => {
                    for (let i = 0; i < keywords.length; i++) {
                      if (keywords[i].length > 150) {
                        return Promise.reject('');
                      }
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select
                maxTagCount="responsive"
                size="large"
                tokenSeparators={[',', '&#13', '&#10']}
                mode="tags"
              ></Select>
            </Form.Item>
            <h3>
              Competitor ASIN(s){' '}
              <a
                href="https://help.pixelme.me/en/articles/6873512-how-to-find-competing-asins"
                target="_blank"
                rel="noreferrer"
              >
                <ExclamationCircleOutlined style={{ fontSize: '13px', color: '#0E32F5' }} />
              </a>
            </h3>
            <MutedText>Enter one ASIN at a time. Up to five competitor ASIN(s) to your product</MutedText>
            <CompetitorsSelector marketplaceId={product.marketplaceId} />
            <h3>{messages.productTags.title}</h3>
            <MutedText>{messages.productTags.description}</MutedText>
            <ProductTagsSelector account={props.account} size="large" />
          </Form>
        )}
      </Fragment>
    );
  }

  function NEW_STORE_OTHER_FORM() {
    return (
      <Fragment key="NEW_STORE_FORM">
        <Form form={storeForm} layout="vertical" initialValues={product} onFinish={saveProduct}>
          <Form.Item required label="Amazon Click-through URL" name="productUrl" rules={[{ required: true }]}>
            <Input size="large" placeholder="Paste your store URL here"></Input>
          </Form.Item>
          <AmazonProviderSelector account={props.account} />
          <Form.Item required label="Name" name="name" rules={[{ required: true }]}>
            <Input size="large" placeholder="My Store"></Input>
          </Form.Item>
          <h3>{messages.productTags.title}</h3>
          <MutedText>{messages.productTags.description}</MutedText>
          <ProductTagsSelector account={props.account} size="large" />
        </Form>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state, ownProps): any {
  return {
    limitations: state.accounts.limitations,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(NewProductModal);

export function InputSearchProduct(props: { value: any, onReset: any, disabled: boolean, loading: boolean, ...rest }) {
  return (
    <div style={{ display: 'flex', gap: 10, alignItems: 'stretch', justifyContent: 'space-between' }}>
      {!props.loading && (
        <Input
          {...props.rest}
          disabled={props.disabled}
          style={{ flex: '1 0 200' }}
          value={props.value}
          size="large"
          placeholder="Paste your product URL here"
        ></Input>
      )}
      {props.disabled && (
        <div>
          <Button style={{ height: '100%' }} icon={<ReloadOutlined />} onClick={() => props.onReset({})}>
            Search for a new product
          </Button>
        </div>
      )}
    </div>
  );
}
