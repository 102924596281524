import React from 'react';

import { Button, Statistic } from 'antd';
import { useIntl } from 'react-intl';
import { useDataProviderModal } from './useDataProviderModal';
import { useDataProviderAllowed } from './useDataProviderAllowed';
import { useFeatureFlag, VIEW_LAST_UPDATE_BUTTON } from 'lib/configcat';

export const DataProviderStats = ({ account }) => {
  const isAllowed = useDataProviderAllowed();
  const [openModal, _] = useDataProviderModal({ account });
  const [showViewLastUpdateButton] = useFeatureFlag(VIEW_LAST_UPDATE_BUTTON);

  const { messages } = useIntl();

  if (!isAllowed || !showViewLastUpdateButton) {
    return <></>;
  }

  return (
    <Statistic
      title={messages.locales.columns.dataProviders}
      valueRender={() => (
        <Button
          type="link"
          onClick={openModal}
          style={{
            paddingBottom: 0,
            marginBottom: -5,
          }}
        >
          {messages.locales.columns.viewLastUpdate}
        </Button>
      )}
    />
  );
};
