import React, { useContext } from 'react';

import { Button, Row, Tooltip } from 'antd';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { DashboardFilterContext, STATUS } from 'context/DashboardFilterProvider';
import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE, VIEW_LAST_UPDATE_BUTTON } from 'lib/configcat';
import { getCurrency } from 'utils/currency';

import { TOOLTIPS } from '../constants';
import { useDataProviderAllowed } from '../DataProvider/useDataProviderAllowed';
import { useDataProviderModal } from '../DataProvider/useDataProviderModal';
import {
  AcosMetrics,
  AddToCartMetrics,
  AdSpendMetrics,
  ClicksMetrics,
  ImpressionsMetrics,
  NetMarginMetrics,
  PurchasesMetrics,
  ReferralBonusMetrics,
  RevenueMetrics,
  RoasMetrics,
} from '../Metrics';
import { InfoIcon } from '../Style';

export type StateT = { adsProducts: Array<productT>, adsReportsRange: string };

const Style = styled.div``;

const CampaignsTitle = styled.div`
  background-color: #fff;
  color: #071629;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  h2 {
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 0.25rem;
  }
  > div {
    display: flex;
    align-items: center;
  }
`;

const FilterStatusWarning = styled.div`
  font-size: 1rem;
  font-weight: 600;
`;

const DataProviderLink = styled(Button).attrs({ type: 'link' })`
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0;
  margin-left: 0.5rem;
  border: none;
  height: 1rem;
`;

export function CampaignsStats({
  stats,
  account,
  productASIN,
  productProviderId,
}: {
  stats: adsReportDataT,
  account: accountT,
  productASIN: string,
  productProviderId: string,
}) {
  const [openDataProviderModal, _] = useDataProviderModal({ account });
  const isDataProviderAllowed = useDataProviderAllowed();
  const [showViewLastUpdateButton] = useFeatureFlag(VIEW_LAST_UPDATE_BUTTON);

  const [showMetricsUpdate, isShowMetricsUpdateLoading] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const { ctx } = useContext(DashboardFilterContext);

  const { currencyCode, currencySymbol } = getCurrency(account);
  const { messages } = useIntl();

  return (
    <Style>
      <CampaignsTitle>
        <div>
          <h2>{messages.locales.columns.campaigns}</h2>
          {ctx.filterCampaignStatus === STATUS.ACTIVE && (
            <FilterStatusWarning>
              - Showing Active Campaigns Only
              <Tooltip title={TOOLTIPS.FILTER_STATUS_WARNING}>
                <InfoIcon />
              </Tooltip>
            </FilterStatusWarning>
          )}
        </div>
        {isDataProviderAllowed && showViewLastUpdateButton && (
          <div>
            API Data:
            <DataProviderLink onClick={openDataProviderModal}>
              {messages.locales.columns.viewLastUpdate}
            </DataProviderLink>
          </div>
        )}
      </CampaignsTitle>
      <Row
        justify="space-between"
        style={{
          columnGap: '0.75rem',
          flexWrap: 'nowrap',
          overflow: 'auto',
          marginTop: '0.25rem',
        }}
      >
        <AdSpendMetrics currency={currencySymbol} cost={stats.adSpent} />
        <ImpressionsMetrics impressions={stats.impressions} />
        <ClicksMetrics clicks={stats.clicks} />
        <AddToCartMetrics adds={stats.amazonTotalAddToCart} />
        <PurchasesMetrics purchases={stats.amazonTotalPurchases} />
        <RevenueMetrics currency={currencySymbol} revenue={stats.amazonTotalSales} />
        <NetMarginMetrics currency={currencySymbol} netMargin={stats.netMargin} />
        {stats.amazonTotalSales > 0 && (
          <ReferralBonusMetrics
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
            brbBonusAmount={stats.brbBonusAmount}
            brbBonusAmountCalculated={stats.brbBonusAmountCalculated}
            brbEnrolled={get(
              account.productProviders.find((p) => p.id === productProviderId),
              'brbEnrolled',
            )}
          />
        )}
        {!isShowMetricsUpdateLoading && !showMetricsUpdate && (
          <>
            <AcosMetrics acos={stats.acos} />
            <RoasMetrics roas={stats.roas} />
          </>
        )}
      </Row>
    </Style>
  );
}
