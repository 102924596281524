import React from 'react';

export function ClockCheck(props) {
  return (
    <svg style={props.style} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2271_13215)">
        <path
          d="M12.0832 15.8333L13.7498 17.5L17.4998 13.75M18.3208 10.4582C18.329 10.3065 18.3332 10.1538 18.3332 10C18.3332 5.39763 14.6022 1.66667 9.99984 1.66667C5.39746 1.66667 1.6665 5.39763 1.6665 10C1.6665 14.5295 5.28026 18.215 9.78191 18.3305M9.99984 5V10L13.1151 11.5577"
          stroke="#101828"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2271_13215">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
